import { useForm } from "@tanstack/react-form-old";
import { useQuery } from "@tanstack/react-query";
import { FinanceType } from "@thedealersconcierge/lib/codecs/tdc";
import { useState } from "react";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Button from "~/components/Button";
import FormInputField from "~/components/FormInputField";
import financeTypeOptions from "~/config/formSelectionOptions/financeTypeOptions";
import { queryClient } from "~/lib/query";

import { useAtomValue } from "jotai";
import { gqlMutationClient } from "~/lib/backend";
import { Link, useNavigate, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import { transactionQuery } from "./_queries/transactionQuery";

/**
 * This page seems to be deprecated and not in use anymore
 */
const DealPage = () => {
  const navigate = useNavigate();
  const dealership = useAtomValue(dealershipAtom);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { transactionId } = useParams(
    "/dashboard/transaction/:transactionId/deal"
  );
  const { data } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );
  const transaction = data?.transaction;
  const handleSubmit = async (values: {
    [key: string]: string | number | boolean;
  }) => {
    setIsSubmitting(true);

    const resp = await gqlMutationClient()({
      updateTransaction: [
        {
          transaction: {
            ...values,
            id: transactionId,
            price: Number(values["price"]),
            downPayment: Number(values["downPayment"]),
            financeType: FinanceType.optional().parse(values["financeType"]),
          },
        },
        {
          __typename: true,
          "...on GraphQLError": {
            message: true,
          },
          "...on MutationUpdateTransactionSuccess": {
            data: {
              status: true,
            },
          },
        },
      ],
    });

    if (
      !resp.updateTransaction ||
      resp.updateTransaction.__typename === "GraphQLError"
    ) {
      console.error(resp.updateTransaction?.message ?? "Unexpected error");

      window.alert("Could not save data. Please try again or contact support.");
    } else {
      await queryClient.invalidateQueries({
        queryKey: ["transaction", transactionId],
      });
      navigate("/dashboard/transaction/:transactionId", {
        params: { transactionId },
      });
    }

    setIsSubmitting(false);
  };
  const handleCancel = () => {
    navigate("/dashboard/transaction/:transactionId", {
      params: { transactionId },
    });
  };
  const form = useForm({
    defaultValues: {
      financeType: data?.transaction?.financeType,
      price: data?.transaction?.price,
      downPayment: data?.transaction?.downPayment,
    } as {
      [key: string]: string | boolean | number;
    },
    onSubmit: handleSubmit,
  });

  return (
    <div className="flex flex-col space-y-4 max-h-dvh overflow-hidden">
      <BreadCrumbsContainer>
        <BreadCrumb title="Transaction">
          <Link to={"/dashboard"}>Transactions</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link
            to={"/dashboard/transaction/:transactionId"}
            params={{ transactionId }}
          >
            {transaction?.title}
          </Link>
        </BreadCrumb>

        <BreadCrumb title="Deal Information" />
      </BreadCrumbsContainer>

      <div className="flex justify-center p-8 overflow-y-scroll">
        <div className="flex w-1/2 min-w-fit flex-col bg-white rounded-xl shadow-md p-10 space-y-6">
          <h1 className="text-heading-1">Deal Information</h1>

          <form.Provider>
            <form
              className="flex flex-col space-y-8"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <div className="flex flex-col space-y-6">
                <FormInputField
                  form={form}
                  fieldName="financeType"
                  type="select"
                  labelText="Deal type"
                  placeholder="Deal type"
                  options={financeTypeOptions}
                  subtitleText="Deal type"
                  disabled={isSubmitting}
                />

                <FormInputField
                  form={form}
                  fieldName="price"
                  type="number"
                  labelText="Financing/Price"
                  placeholder="Financing/Price"
                  subtitleText="Financing/Price"
                  disabled={isSubmitting}
                />

                <FormInputField
                  form={form}
                  fieldName="downPayment"
                  type="number"
                  labelText="Down Payment"
                  placeholder="Down Payment"
                  subtitleText="Down Payment"
                  disabled={isSubmitting}
                />
              </div>

              <div className="flex flex-row justify-between">
                <Button
                  variant="TERTIARY"
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>

                <Button type="submit" loading={isSubmitting}>
                  Save
                </Button>
              </div>
            </form>
          </form.Provider>
        </div>
      </div>
    </div>
  );
};

export default DealPage;
