import { useForm } from "@tanstack/react-form-old";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { useState } from "react";
import { ChevronRight, X } from "react-feather";
import { toast } from "react-toastify";
import { SubmitTo } from "~/__generated__/backend/zeus";
import Button from "~/components/Button";
import FormInputField from "~/components/FormInputField";
import Modal from "~/components/Modal";
import DocumentIcon from "~/components/icons/DocumentIcon";
import BuyerBadge from "~/pages/(public)/_components/BuyerBadge";
import WarningBanner from "~/pages/(public)/_components/WarningBanner";
import { useModals, useNavigate, useParams } from "~/router";
import { refetchTransactionQuery } from "../_queries/transactionQuery";
import ConfirmModal from "./_components/ConfirmModal";
import CreditApplicationAddressDetailCard from "./_components/CreditApplicationAddressDetailCard";
import CreditApplicationEmploymentDetailCard from "./_components/CreditApplicationEmploymentDetailCard";
import Header from "./_components/Header";
import { transactionHardCreditQuery } from "./_queries/hardCreditQuery";
import {
  LendingPortalAddressSchema,
  LendingPortalEmploymentSchema,
  parseAddressesFromUnknown,
  parseEmploymentsFromUnknown,
} from "./_utils";

export default function LendingPortalPage() {
  const navigate = useNavigate();
  const modals = useModals();
  const { transactionId } = useParams("/dashboard/transaction/:transactionId");
  const { data } = useQuery(transactionHardCreditQuery(transactionId));
  const [confirmationModalIsOpen, setConfirmationIsModalOpen] = useState(false);
  const [submitTo, setSubmitTo] = useState<null | SubmitTo>(null);

  const openConfirmModal = (submitTo: SubmitTo) => {
    setSubmitTo(submitTo);
    setConfirmationIsModalOpen(true);
  };
  const closeConfirmModal = () => {
    setSubmitTo(null);
    setConfirmationIsModalOpen(true);
  };

  const user = data?.transaction?.buyer;
  const hasCoBuyer = !!data?.transaction?.coBuyer;

  const isCobuyerDataComplete =
    hasCoBuyer &&
    !!data.transaction?.coBuyer?.hardCreditApplications?.edges?.length;

  const currentHardCreditApplication =
    user?.hardCreditApplications?.edges?.at(0)?.node;

  const addresses: LendingPortalAddressSchema[] = parseAddressesFromUnknown(
    currentHardCreditApplication?.residentialAddresses
  );

  const employments: LendingPortalEmploymentSchema[] =
    parseEmploymentsFromUnknown(currentHardCreditApplication?.employments);

  const currentAddress: LendingPortalAddressSchema | undefined = addresses.find(
    (el) => el.timelinePosition === 0
  );

  const handleBack = () => {
    navigate("/dashboard/transaction/:transactionId", {
      params: { transactionId },
    });
  };

  const handleGotoCoBuyerLendingPortal = () => {
    modals.open(
      "/dashboard/transaction/[transactionId]/lending-portal/coBuyer",
      {
        params: { transactionId },
      }
    );
  };

  const form = useForm({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      middleName: user?.middleName,
      socialSecurityNumber: user?.socialSecurityNumber,
      dateOfBirth: user?.birthdate
        ? format(new Date(user?.birthdate), "dd/MM/yyyy")
        : "",
      state: currentAddress?.state,
      zipCode: currentAddress?.zipCode,
      email: user?.user?.email,
      phoneNumber: user?.user?.phoneNumber,
      homePhoneNumber: currentHardCreditApplication?.homePhoneNumber,
    },
    onSubmit: () => {},
  });

  return (
    <Modal title="ID" isOpen onClose={close} isShowTitle={false}>
      {submitTo && (
        <ConfirmModal
          submitTo={submitTo}
          isOpen={confirmationModalIsOpen}
          transactionId={transactionId}
          onError={(msg) => {
            toast.error(`An error happened: ${msg}`);
            closeConfirmModal();
          }}
          onSuccess={() => {
            if (submitTo === SubmitTo.DEALER_TRACK)
              toast.success("Successfully pushed application to Dealer Track", {
                // Ideally we should use data-test-id, but I'm not sure if we have it
                className: "toast-push-success",
              });
            else {
              toast.success("Successfully pushed application to Route1", {
                // Ideally we should use data-test-id, but I'm not sure if we have it
                className: "toast-push-success",
              });
            }

            closeConfirmModal();
            modals.close();
            refetchTransactionQuery(transactionId);
          }}
          onCancel={closeConfirmModal}
        />
      )}
      <div className="flex flex-col h-dvh w-dvw">
        <Header
          title={
            <div className="flex flex-row gap-4 items-center">
              <DocumentIcon className="size-4 icon-primary" />
              <p className="text-heading-2">Credit Application</p>
            </div>
          }
          rightElement={
            <button
              onClick={handleBack}
              disabled={false}
              className="px-4 justify-center items-center"
            >
              <X />
            </button>
          }
        />

        <div className="flex flex-col h-full overflow-auto">
          <div className="flex flex-col items-center px-8 lg:px-16 bg-gray-50 w-full">
            <div className="flex flex-col max-w-screen-xl py-12  w-full space-y-5">
              {hasCoBuyer && !isCobuyerDataComplete && (
                <WarningBanner text="Co-Buyer needs to submit their Hard Credit application in order to submit this application." />
              )}
              <div className="flex flex-row gap-4 items-center justify-between w-full">
                <div className="flex flex-row gap-4 items-center">
                  <h1 className="text-heading-1">Basic Information</h1>
                  <BuyerBadge isPrimaryBuyer />
                </div>
                {hasCoBuyer && (
                  <Button
                    onClick={handleGotoCoBuyerLendingPortal}
                    variant="TEXT_ONLY"
                    disabled={!isCobuyerDataComplete}
                  >
                    <div className="flex flex-row gap-4 items-center">
                      <p className="text-heading-2">Co-Buyer’s Application</p>
                      <ChevronRight />
                    </div>
                  </Button>
                )}
              </div>
              <form.Provider>
                <div className="grid grid-cols-3 gap-4">
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="firstName"
                    labelText="First Name"
                    placeholder="First Name"
                    disabled={true}
                  />
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="middleName"
                    labelText="Middle Name"
                    placeholder="Middle Name"
                    disabled={true}
                  />
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="lastName"
                    labelText="Last Name"
                    placeholder="Last Name"
                    disabled={true}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="dateOfBirth"
                    labelText="Date of Birth"
                    placeholder="Date of Birth"
                    disabled={true}
                  />
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="socialSecurityNumber"
                    labelText="Social Security Number"
                    placeholder="Social Security Number"
                    disabled={true}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="zipCode"
                    labelText="ZIP Code"
                    placeholder="ZIP Code"
                    disabled={true}
                  />
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="state"
                    labelText="State"
                    placeholder="State"
                    disabled={true}
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="homePhoneNumber"
                    labelText="Home Phone Number"
                    placeholder="Home Phone Number"
                    disabled={true}
                  />
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="phoneNumber"
                    labelText="Phone Number"
                    placeholder="Phone Number"
                    disabled={true}
                  />
                </div>

                <div className="grid grid-cols-1 gap-4">
                  <FormInputField
                    form={form}
                    type="text"
                    fieldName="email"
                    labelText="Email"
                    placeholder="Email"
                    disabled={true}
                  />
                </div>
              </form.Provider>
            </div>
          </div>

          <div className="flex flex-col items-center px-8 lg:px-16 space-y-5 w-full">
            <div className="flex flex-col max-w-screen-xl py-12 w-full space-y-5">
              <h1 className="text-heading-1 font-normal">
                Address Information
              </h1>
              {addresses.map((el) => (
                <CreditApplicationAddressDetailCard address={el} />
              ))}
            </div>
          </div>

          <div className="flex flex-col items-center px-8 lg:px-16 space-y-5 w-full">
            <div className="flex flex-col max-w-screen-xl py-12 w-full space-y-5">
              <h1 className="text-heading-1 font-normal">
                Employment Information
              </h1>
              {employments.map((el) => (
                <CreditApplicationEmploymentDetailCard employment={el} />
              ))}
            </div>
          </div>

          <div className="flex flex-col items-center px-8 lg:px-16 w-full">
            <div className="flex flex-col max-w-screen-xl py-12 w-full">
              <div className="flex flex-row w-full justify-end gap-12">
                {data?.transaction?.dealership?.hasEnabledDealerTrack && (
                  <Button
                    variant="PRIMARY"
                    onClick={() => openConfirmModal(SubmitTo.DEALER_TRACK)}
                    disabled={!!data?.transaction.hardCreditApplDmsSubmittedAt}
                  >
                    Push to Dealer Track
                  </Button>
                )}
                {data?.transaction?.dealership?.hasEnabledRoute1 && (
                  <Button
                    dataTestId="lending-portal-push-to-route1"
                    variant="PRIMARY"
                    onClick={() => openConfirmModal(SubmitTo.ROUTE_ONE)}
                    disabled={!!data?.transaction.hardCreditApplDmsSubmittedAt}
                  >
                    Push to Route 1
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
