import Button from "~/components/Button";
import SectionDataRow from "~/components/SectionDataRow";
import CarBackIcon from "~/components/icons/CarBackIcon";
import CarFrontIcon from "~/components/icons/CarFrontIcon";
import CarInspectionIcon from "~/components/icons/CarInspectionIcon";
import CarOdometerIcon from "~/components/icons/CarOdometerIcon";
import CarSeatIcon from "~/components/icons/CarSeatIcon";
import CarSideIcon from "~/components/icons/CarSideIcon";
import TradeVehicleIcon from "~/components/icons/TradeVehicleIcon";
import {
  getReadableBodyType,
  getReadableVehicleCondition,
} from "~/lib/enumReadable";
import { useNavigate } from "~/router";
import { TransactionQueryType } from "../_queries/transactionQuery";
import RecallCheckModal from "./RecallCheckModal";
import VinAuditModal from "./VinAuditModal";

type Props = {
  transaction: TransactionQueryType["transaction"];
};

export default function TransactionTradeVehicle({ transaction }: Props) {
  const navigate = useNavigate();
  const tradeVehicle = transaction?.tradeVehicle;

  const buyer = transaction?.buyer;

  const handleEditTradeVehicle = () => {
    navigate(
      {
        pathname: "/dashboard/transaction/:transactionId/vehicle/trade",
      },
      {
        params: {
          transactionId: transaction?.id ?? "no-transaction-id",
        },
      }
    );
  };

  return (
    <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-4 justify-between w-full">
          <div className="flex flex-row space-x-2 items-center">
            <div className="relative">
              <TradeVehicleIcon className="w-5 aspect-square text-dark-gray" />
            </div>

            <h2 className="text-heading-2 text-very-dark-gray">
              Vehicle for Trade
            </h2>
          </div>

          <div className="flex flex-row space-x-4">
            {tradeVehicle?.id && transaction?.id && (
              <>
                <VinAuditModal transactionId={transaction.id} />
                <RecallCheckModal
                  vehicleRole="TRADE"
                  transactionId={transaction.id}
                />
              </>
            )}

            <Button
              variant="TEXT_ONLY"
              onClick={() => {
                handleEditTradeVehicle();
              }}
            >
              {tradeVehicle ? "Edit" : "Add"}
            </Button>
          </div>
        </div>

        {/**
         * TODO: Hook up CARFAX
         */}
        {/* <Button variant="SECONDARY">CARFAX</Button> */}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col gap-4">
            <SectionDataRow label="Registered Owner" value={"-"} />

            <SectionDataRow
              label="Type"
              value={
                tradeVehicle ? (tradeVehicle.isUsed ? "Used" : "New") : "-"
              }
            />

            <SectionDataRow label="VIN#" value={tradeVehicle?.vin ?? "-"} />

            <SectionDataRow
              label="Body Type"
              value={
                tradeVehicle?.bodyType
                  ? getReadableBodyType(tradeVehicle.bodyType)
                  : "-"
              }
            />

            <SectionDataRow label="Make" value={tradeVehicle?.make ?? "-"} />
          </div>

          <div className="flex flex-col gap-4">
            <SectionDataRow label="Model" value={tradeVehicle?.model ?? "-"} />

            <SectionDataRow label="Year" value={tradeVehicle?.year ?? "-"} />

            <SectionDataRow label="Color" value={tradeVehicle?.color ?? "-"} />

            <SectionDataRow
              label="Condition"
              value={
                tradeVehicle?.condition
                  ? getReadableVehicleCondition(tradeVehicle.condition)
                  : "-"
              }
            />

            <SectionDataRow
              label="Mileage"
              value={tradeVehicle?.mileage?.toLocaleString() ?? "-"}
            />

            <SectionDataRow
              label="Current monthly payment"
              value={
                buyer?.currentMonthlyPayment
                  ? `$${buyer.currentMonthlyPayment.toLocaleString()}`
                  : "-"
              }
            />
          </div>
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
          <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
            {tradeVehicle?.pictureFront?.url && (
              <img
                className="w-full h-full"
                style={{ objectFit: "cover" }}
                src={tradeVehicle.pictureFront.url}
              />
            )}

            {!tradeVehicle?.pictureFront?.url && (
              <div className="flex flex-col items-center space-y-4 pt-2">
                <CarFrontIcon className="h-14 text-dark-gray" />

                <div className="text-block text-dark-gray">Front</div>
              </div>
            )}
          </div>

          <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
            {tradeVehicle?.pictureBack?.url && (
              <img
                className="w-full h-full"
                style={{ objectFit: "cover" }}
                src={tradeVehicle.pictureBack.url}
              />
            )}

            {!tradeVehicle?.pictureBack?.url && (
              <div className="flex flex-col items-center space-y-4 pt-2">
                <CarBackIcon className="h-14 text-dark-gray" />

                <div className="text-block text-dark-gray">Back</div>
              </div>
            )}
          </div>

          <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
            {tradeVehicle?.pictureLeftSide?.url && (
              <img
                className="w-full h-full"
                style={{ objectFit: "cover" }}
                src={tradeVehicle.pictureLeftSide.url}
              />
            )}

            {!tradeVehicle?.pictureLeftSide?.url && (
              <div className="flex flex-col items-center space-y-4 pt-2">
                <CarSideIcon className="h-12 text-dark-gray" />

                <div className="text-block text-dark-gray">Left Side</div>
              </div>
            )}
          </div>

          <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
            {tradeVehicle?.pictureRightSide?.url && (
              <img
                className="w-full h-full"
                style={{ objectFit: "cover" }}
                src={tradeVehicle.pictureRightSide.url}
              />
            )}

            {!tradeVehicle?.pictureRightSide?.url && (
              <div className="flex flex-col items-center space-y-4 pt-2">
                <CarSideIcon className="h-12 text-dark-gray -scale-x-100" />

                <div className="text-block text-dark-gray">Right Side</div>
              </div>
            )}
          </div>

          <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
            {tradeVehicle?.pictureInterior?.url && (
              <img
                className="w-full h-full"
                style={{ objectFit: "cover" }}
                src={tradeVehicle.pictureInterior.url}
              />
            )}

            {!tradeVehicle?.pictureInterior?.url && (
              <div className="flex flex-col items-center space-y-4 pt-2">
                <CarSeatIcon className="h-14 text-dark-gray -scale-x-100" />

                <div className="text-block text-dark-gray">Interior</div>
              </div>
            )}
          </div>

          <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
            {tradeVehicle?.pictureOdometer?.url && (
              <img
                className="w-full h-full"
                style={{ objectFit: "cover" }}
                src={tradeVehicle.pictureOdometer.url}
              />
            )}

            {!tradeVehicle?.pictureOdometer?.url && (
              <div className="flex flex-col items-center space-y-4 pt-2">
                <CarOdometerIcon className="h-14 text-dark-gray -scale-x-100" />

                <div className="text-block text-dark-gray">Odometer</div>
              </div>
            )}
          </div>

          <div className="flex items-center justify-center relative rounded-md overflow-hidden bg-very-light-gray aspect-[5/4]">
            {tradeVehicle?.pictureVinNumber?.url && (
              <img
                className="w-full h-full"
                style={{ objectFit: "cover" }}
                src={tradeVehicle.pictureVinNumber.url}
              />
            )}

            {!tradeVehicle?.pictureVinNumber?.url && (
              <div className="flex flex-col items-center space-y-4 pt-2">
                <CarInspectionIcon className="h-14 text-dark-gray" />

                <div className="text-block text-dark-gray">VIN Number</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
