import { Tag } from "@thedealersconcierge/components";
import { TagColorTypes } from "@thedealersconcierge/components/src/tag";
import { TransactionStatusSchema } from "@thedealersconcierge/lib/codecs/tdc";
import { getReadableTransactionStatus } from "@thedealersconcierge/lib/codecs/util";
import { FC } from "react";

const StatusTag: FC<{
  status: TransactionStatusSchema;
  dataTestid?: string;
}> = ({ status, dataTestid }) => {
  // These color map are based on figma design tokens
  const statusToColorMap: Record<TransactionStatusSchema, TagColorTypes> = {
    REGISTERED: "RED",
    SIGNED_UP: "LIGHT_RED",
    IN_PROGRESS: "BLUE",
    PREQUAL_SUBMITTED: "YELLOW",
    CREDIT_APP_SUBMITTED: "LIGHT_GREEN",
    FILE_COMPLETE: "GREEN",
    READY_FOR_FNI: "TEAL",
    DELIVERED: "GRAY",

    // Deprecated statuses
    WEB_LEAD: "GRAY",
    WEB_APPLICATION: "GRAY",
    COMPLETED: "GRAY",
    DISASSOCIATED: "GRAY",
    DEAD: "GRAY",
    RULE_90_DAYS: "GRAY",
    NOT_STARTED: "GRAY",
  };

  return (
    <Tag
      dataTestId={dataTestid}
      color={statusToColorMap[status]}
      label={getReadableTransactionStatus(status)}
    />
  );
};

export default StatusTag;
