import { useForm } from "@tanstack/react-form-old";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { cognitoSubmitMfa } from "~/actions/cognitoMfaAction";
import Button from "~/components/Button";
import { useNavigate } from "~/router";

export default function MfaInput({ mfaType }: { mfaType: "TOTP" | "SMS" }) {
  const navigate = useNavigate();
  const [error, setError] = useState<null | string>(null);
  const { t } = useTranslation();

  const form = useForm({
    defaultValues: {
      otp: "",
    },
    onSubmit: async (value) => {
      try {
        await cognitoSubmitMfa(value.otp);
        navigate("/dashboard");
      } catch (e: any) {
        setError(e.message);
      }
    },
  });

  return (
    <form.Provider>
      <form
        className="rounded-2xl shadow-2xl w-[444px] flex flex-col space-y-8 items-center p-6"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <h2 className="text-2xl font-bold">
          {mfaType === "TOTP" ? t("App Verification") : t("SMS Verification")}
        </h2>

        {mfaType === "TOTP" ? (
          <p>Enter the code from your authenticator app.</p>
        ) : (
          <p data-test-id="admin-login-mfa-body">
            Enter the code sent to you via a text message.
          </p>
        )}

        <form.Field
          name="otp"
          children={(field) => (
            <input
              name={field.name}
              value={field.state.value ?? ""}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              placeholder="One time code"
              type="text"
              className="w-full border border-[#E9EBED] p-4 rounded-lg"
              data-test-id="admin-login-otp-input"
            />
          )}
        />

        {error && (
          <div className="w-full rounded-md bg-red-500 text-white font-semibold p-4">
            {error}
          </div>
        )}

        <form.Subscribe
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          children={([canSubmit, isSubmitting]) => (
            <Button
              type="submit"
              disabled={!canSubmit}
              loading={isSubmitting}
              dataTestId="admin-login-otp-submit"
            >
              Submit Code
            </Button>
          )}
        />
      </form>
    </form.Provider>
  );
}
