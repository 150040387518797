import { Button } from "@thedealersconcierge/components";
import { Role } from "~/__generated__/backend/zeus";
import AssignModal from "./AssignModal";

type Props = {
  transactionId: string;
  assignRole: Role;
  onDidAssign?: () => void | Promise<void>;
  disabled?: boolean;
};

export default function AssignButton(props: Props) {
  return (
    <AssignModal {...props}>
      {({ openModal }) => (
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openModal();
          }}
          disabled={props.disabled}
          label="Assign"
          variant="GHOST"
          size="SMALL"
        />
      )}
    </AssignModal>
  );
}
