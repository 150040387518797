import { FormApi } from "@tanstack/react-form-old";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { FC } from "react";

type DropDownOption = {
  value: string;
  label: string;
};

/**
 * @deprecated Use the shared component library
 * @param param0
 * @returns
 */
const FormInputField: FC<{
  form: FormApi<any, unknown>;
  onChange?: (
    key: string,
    value?: string | boolean | number
  ) => string | undefined;
  fieldName: string;
  type: string;
  labelText: string;
  placeholder?: string;
  subtitleText?: string;
  options?: DropDownOption[];
  disabled?: boolean;
  required?: boolean;
}> = ({
  form,
  onChange,
  fieldName,
  type,
  labelText,
  placeholder,
  subtitleText,
  options,
  disabled,
  required,
}) => {
  return (
    <form.Field
      name={fieldName}
      onChange={(value) => {
        if (required && !value.toString().length) {
          if (onChange) {
            onChange(fieldName, value);
          }

          return "The field is required";
        }

        if (onChange) {
          return onChange(fieldName, value);
        }

        return undefined;
      }}
      children={(field) => {
        return (
          <div className="flex flex-col space-y-2">
            <div>
              <label htmlFor={field.name} className="hidden">
                {labelText}
              </label>

              {type !== "select" && (
                <input
                  type={type}
                  name={field.name}
                  className={classNames(
                    "w-full px-3 text-placeholder appearance-none outline-none border-b-[0.5px] border-x-gray-100",
                    {
                      "py-2": type !== "date",
                      "py-1.5": type === "date",
                      "bg-gray text-dark-gray": disabled,
                    }
                  )}
                  onChange={(e) => field.handleChange(e.target.value)}
                  value={
                    type === "date" && !!field.state.value
                      ? format(
                          parseISO(field.state.value as string),
                          "yyyy-MM-dd"
                        )
                      : ((field.state.value as string) ?? "")
                  } // We have to do the cast to string. Boolean values are not handled by this component.
                  placeholder={placeholder}
                  disabled={disabled}
                />
              )}

              {type === "select" && !!options && (
                <select
                  name={field.name}
                  className={classNames("dropdown w-full", {
                    "text-dark-gray": !field.state.value,
                    "bg-gray text-dark-gray": disabled,
                  })}
                  onChange={(e) => field.handleChange(e.target.value)}
                  value={
                    field.state.value !== ""
                      ? (field.state.value as string) // We can do the cast to string as we know that selection options have to be of type string
                      : "placeholder"
                  }
                  disabled={disabled}
                >
                  <option
                    value="placeholder"
                    disabled
                    selected={!field.state.value}
                  >
                    {placeholder}
                  </option>

                  {options.map((option) => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </select>
              )}
            </div>

            <div className="flex flex-row">
              {subtitleText && (
                <div className="text-input-label ml-3 text-dark-gray ">
                  {subtitleText}
                </div>
              )}

              {required && !field.state.meta.touchedErrors.length && (
                <div className="text-input-label text-dark-gray ml-3">
                  (required)
                </div>
              )}

              {field.state.meta.touchedErrors.map((error, index) => {
                return (
                  <div className="text-input-label ml-3 text-error" key={index}>
                    {error}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    />
  );
};

export default FormInputField;
