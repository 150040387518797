import { FormApi } from "@tanstack/react-form-old";
import classNames from "classnames";
import { FC } from "react";
import PhoneInput from "react-phone-number-input";

/**
 * @deprecated Use the shared component library
 * @param param0
 * @returns
 */
const PhoneNumberInput: FC<{
  form: FormApi<any, unknown>;
  fieldName: string;
  required?: boolean;
  error?: string;
  subtitleText?: string;
  placeholder?: string;
  disabled?: boolean;
}> = ({
  form,
  fieldName,
  required,
  error,
  subtitleText,
  placeholder,
  disabled,
}) => {
  return (
    <form.Field
      name={fieldName}
      onChange={(v) => (!v ? "Phone Number is required" : undefined)}
    >
      {(field) => (
        <div className="flex flex-col space-y-2">
          <label htmlFor={field.name} className="hidden">
            Phone Number
          </label>

          <PhoneInput
            defaultCountry="US"
            placeholder={placeholder ?? "Phone Number"}
            name={field.name}
            onChange={(v) => field.handleChange(v ?? "")}
            value={field.state.value ?? ""}
            onBlur={field.handleBlur}
            disabled={disabled}
            className={classNames(
              "border-b-[0.5px] border-light-gray py-2 px-3 space-x-2",
              { "bg-gray-300 *:bg-gray-300 text-dark-gray": disabled }
            )}
          />

          <div className="flex flex-row">
            {subtitleText &&
              !error &&
              !field.state.meta.touchedErrors.length && (
                <div className="text-input-label ml-3 text-dark-gray ">
                  {subtitleText}
                </div>
              )}

            {required && !error && !field.state.meta.touchedErrors.length && (
              <div className="text-input-label text-dark-gray ml-3">
                (required)
              </div>
            )}

            {!error &&
              field.state.meta.touchedErrors.map((e, index) => {
                return (
                  <div className="text-input-label ml-3 text-error" key={index}>
                    {e}
                  </div>
                );
              })}

            {error && (
              <div className="text-input-label ml-3 text-error">{error}</div>
            )}
          </div>
        </div>
      )}
    </form.Field>
  );
};

export default PhoneNumberInput;
