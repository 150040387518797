import { captureException } from "@sentry/react";
import { useForm } from "@tanstack/react-form";
import { useQuery } from "@tanstack/react-query";
import { Button, TextInput } from "@thedealersconcierge/components";
import Select from "@thedealersconcierge/components/src/input/select";
import { useAtomValue } from "jotai";
import { FC, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { VehicleType } from "~/__generated__/backend/zeus";
import updateVehicleAction from "~/actions/vehicles/updateVehicleAction";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Spinner from "~/components/Spinner";
import stateOptions from "~/config/formSelectionOptions/stateOptions";
import { gqlMutationClient } from "~/lib/backend";
import { queryClient } from "~/lib/query";
import { Link, useNavigate, useParams } from "~/router";
import { VehicleType as Vehicle } from "~/selectors/vehicleSelector";
import { dealershipAtom } from "~/state";
import { transactionQuery } from "../_queries/transactionQuery";
import VehicleForm, { VehicleFormType } from "./_components/VehicleForm";

const VehiclePageContent: FC<{
  transactionId: string;
  transactionTitle?: string;
  vehicle: Partial<Vehicle | undefined>;
}> = ({ transactionId, transactionTitle, vehicle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isPrefilling, setIsPrefilling] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateForPrefilling, setStateForPrefilling] = useState<
    string | undefined
  >();
  const [plateForPrefilling, setPlateForPrefilling] = useState<
    string | undefined
  >();

  const handleCancel = () => {
    navigate("/dashboard/transaction/:transactionId", {
      params: { transactionId },
    });
  };

  const form = useForm<VehicleFormType>({
    defaultValues: {
      vin: vehicle?.vin ?? "",
      bodyType: vehicle?.bodyType,
      make: vehicle?.make ?? "",
      model: vehicle?.model ?? "",
      year: vehicle?.year ?? "",
      color: vehicle?.color ?? "",
      condition: vehicle?.condition,
      isUsed:
        vehicle?.isUsed !== undefined
          ? vehicle.isUsed
            ? "USED"
            : "NEW"
          : "USED",
      mileage: vehicle?.mileage?.toString() ?? "",
      trim: vehicle?.trim ?? "",
      principalPriorUse: vehicle?.principalPriorUse,
    },
    onSubmit: async (value) => {
      try {
        const values = value.value;
        setIsSubmitting(true);

        if (vehicle?.id) {
          // We are updating an existing vehicle
          await updateVehicleAction(
            transactionId,
            VehicleType.TRADE,
            vehicle.id,
            {
              ...values,
              isUsed: values.isUsed ? values.isUsed === "USED" : undefined,
              mileage:
                values.mileage.length > 0
                  ? parseFloat(values.mileage)
                  : undefined,
            }
          );
          await queryClient.invalidateQueries({
            queryKey: ["transaction", transactionId],
          });

          toast.success("Successfully updated vehicle");

          navigate("/dashboard/transaction/:transactionId", {
            params: { transactionId },
          });
        } else {
          // We are creating a new
          const resp = await gqlMutationClient()({
            createVehicle: [
              {
                transactionId,
                vehicleType: VehicleType.TRADE,
                vehicle: {
                  ...values,
                  vin: values.vin.length > 0 ? values.vin : undefined,
                  isUsed: values.isUsed ? values.isUsed === "USED" : undefined,
                  mileage:
                    values.mileage.length > 0
                      ? parseFloat(values.mileage)
                      : undefined,
                },
              },
              {
                __typename: true,
                "...on GraphQLError": {
                  message: true,
                },
                "...on MutationCreateVehicleSuccess": {
                  data: {
                    status: true,
                  },
                },
              },
            ],
          });

          if (
            !resp.createVehicle ||
            resp.createVehicle.__typename === "GraphQLError"
          ) {
            throw Error(resp.createVehicle?.message);
          } else {
            await queryClient.invalidateQueries({
              queryKey: ["transaction", transactionId],
            });

            toast.success("Successfully created vehicle");
            navigate("/dashboard/transaction/:transactionId", {
              params: { transactionId },
            });
          }
        }
      } catch (e) {
        console.error(e);
        captureException(e);

        if (e instanceof Error && e.message) {
          toast.error(e.message);
        } else {
          toast.error("Failed to save vehicle data");
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handlePrefillFromPlate = async () => {
    if (!stateForPrefilling || !plateForPrefilling) {
      return;
    }
    try {
      setIsPrefilling(true);
      const prefillValues = await gqlMutationClient()({
        prefillLicensePlate: [
          {
            state: stateForPrefilling,
            licensePlate: plateForPrefilling,
          },
          {
            __typename: true,
            "...on MutationPrefillLicensePlateSuccess": {
              data: {
                make: true,
                model: true,
                year: true,
                trim: true,
                vin: true,
              },
            },
            "...on GraphQLError": {
              message: true,
            },
          },
        ],
      });

      if (prefillValues.prefillLicensePlate?.__typename === "GraphQLError") {
        toast.error("Could not fetch prefill values");
        return;
      }

      const values = prefillValues.prefillLicensePlate?.data;

      form.setFieldValue("vin", values?.vin ?? "");
      form.setFieldValue("make", values?.make ?? "");
      form.setFieldValue("model", values?.model ?? "");
      form.setFieldValue("year", values?.year ?? "");
      form.setFieldValue("trim", values?.trim ?? "");
    } finally {
      setIsPrefilling(false);
    }
  };

  return (
    <Fragment>
      <BreadCrumbsContainer>
        <BreadCrumb title="Transaction">
          <Link to={"/dashboard"}>Transactions</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link
            to={"/dashboard/transaction/:transactionId"}
            params={{ transactionId }}
          >
            {transactionTitle}
          </Link>
        </BreadCrumb>

        <BreadCrumb title="Co-Buyer">
          <span>{t("Trade Vehicle")}</span>
        </BreadCrumb>
      </BreadCrumbsContainer>

      <div className="flex justify-center p-8">
        <div className="flex w-1/2 min-w-fit flex-col bg-white rounded-xl shadow-md p-10 space-y-6">
          <h2 className="text-heading-2">{t("Trade Vehicle")}</h2>

          <form
            className="flex flex-col space-y-8"
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();
              void form.handleSubmit();
            }}
          >
            <div className="grid grid-cols-2 gap-6 w-full">
              <div className="col-span-2 w-full">
                <div className="flex flex-row gap-4 w-full">
                  <div className="flex-grow">
                    <div className="w-full grid grid-cols-7 gap-4">
                      <Select
                        value={stateForPrefilling}
                        label={t("State")}
                        placeholder={t("State")}
                        required={false}
                        disabled={isSubmitting || isPrefilling}
                        onSelect={(option) => {
                          setStateForPrefilling(option.value);
                        }}
                        backgroundType={"LIGHT"}
                        className="col-span-2"
                        options={stateOptions}
                      />

                      <TextInput
                        value={plateForPrefilling ?? ""}
                        label={t("License Plate")}
                        placeholder={t("License Plate")}
                        required={false}
                        disabled={isSubmitting || isPrefilling}
                        onChange={(v) => {
                          setPlateForPrefilling(v);
                        }}
                        backgroundType={"LIGHT"}
                        className="col-span-3"
                      />
                      <div className="col-span-2 flex w-full h-full justify-center items-center">
                        <Button
                          isLoading={isPrefilling}
                          type="button"
                          onClick={() => {
                            void handlePrefillFromPlate();
                          }}
                          label={t("Prefill")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />

            <VehicleForm
              form={form}
              vehicleType={VehicleType.TRADE}
              isSubmitting={isSubmitting}
              handleCancel={handleCancel}
            />
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default function TradeVehiclePage() {
  const { transactionId } = useParams(
    "/dashboard/transaction/:transactionId/vehicle/trade"
  );
  const dealership = useAtomValue(dealershipAtom);
  const { data: transactionData, isLoading } = useQuery(
    transactionQuery(transactionId, dealership?.activeDealershipPerms)
  );

  return (
    <div className="flex flex-col space-y-4">
      {!isLoading && transactionData?.transaction ? (
        <VehiclePageContent
          transactionId={transactionId}
          transactionTitle={transactionData.transaction.title}
          vehicle={transactionData.transaction.tradeVehicle}
        />
      ) : (
        <div className="flex justify-center min-h-dvh">
          <Spinner />
        </div>
      )}
    </div>
  );
}
