import { gqlMutationClient } from "~/lib/backend";

const pushDataToCrmAction = async (transactionId: string) => {
  const resp = await gqlMutationClient()({
    pushDataToCrms: [
      {
        transactionId,
      },
      {
        __typename: true,
        "...on GraphQLError": {
          message: true,
        },
        "...on MutationPushDataToCrmsSuccess": {
          data: {
            status: true,
          },
        },
      },
    ],
  });

  if (
    !resp.pushDataToCrms ||
    resp.pushDataToCrms.__typename === "GraphQLError"
  ) {
    throw new Error(resp.pushDataToCrms?.message ?? "Unexpected error");
  }
};

export default pushDataToCrmAction;
