import classNames from 'classnames';
import { ChangeEvent, cloneElement, FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { InputBackgroundType } from '../inputConfig';

type SearchBoxProps = {
  /**
   * dataTestId for E2E testing
   */
  dataTestId?: string;

  /**
   * The current value of the search input
   */
  value: string;

  /**
   * Placeholder text for the search input
   */
  placeholder: string;

  /**
   * Callback that triggers whenever the user types in the input
   */
  onChange: (value: string) => void;

  /**
   * Optional callback for blur event
   */
  onBlur?: () => void;

  /**
   * Whether the input is disabled
   */
  disabled: boolean;

  /**
   * Additional className
   */
  className?: string;

  /**
   * Background type (DARK or LIGHT)
   */
  backgroundType?: InputBackgroundType;

  /**
   * An optional icon to render on the left side of the input
   */
  iconLeft?: JSX.Element;
};

const SearchBox: FC<SearchBoxProps> = ({
  dataTestId,
  value,
  placeholder,
  onChange,
  onBlur,
  disabled,
  className,
  backgroundType = 'DARK',
  iconLeft
}) => {
  const [isTyping, setIsTyping] = useState(false);

  return (
    <div
      className={twMerge(
        classNames('flex flex-col w-full space-y-spacing-01', {
          'opacity-40': disabled
        }),
        className
      )}
    >
      {/* Input Container */}
      <div
        className={twMerge(
          classNames('flex flex-row border-b border-primary items-center', {
            'border-interactive': isTyping,
            'bg-secondary': backgroundType === 'DARK',
            'bg-primary': backgroundType === 'LIGHT'
          })
        )}
        data-test-id={dataTestId}
      >
        <div className="relative flex items-center w-full">
          {/* ICON LEFT */}
          {iconLeft && (
            <span className="absolute left-2 text-tertiary w-4 h-4 flex items-center justify-center">
              {cloneElement(iconLeft, {
                className: classNames('w-4 h-4 text-tertiary')
              })}
            </span>
          )}

          {/* INPUT */}
          <input
            data-test-id={`${dataTestId}-input`}
            className="body-01 w-full px-spacing-02 py-[6px] pl-8 text-primary outline-none placeholder:text-tertiary disabled:text-tertiary bg-transparent"
            placeholder={placeholder}
            type="search" // This enables the "x" to clear
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange(e.target.value);
            }}
            onFocus={() => {
              setIsTyping(true);
            }}
            onBlur={() => {
              setIsTyping(false);
              onBlur?.();
            }}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchBox;
