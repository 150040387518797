import { ReactFormApi } from "@tanstack/react-form";
import {
  Button,
  NumberInput,
  Select,
  TextInput,
} from "@thedealersconcierge/components";
import {
  VehicleCondition as VehicleConditionSchema,
  VehiclePrincipalPriorUse as VehiclePrincipalPriorUseSchema,
} from "@thedealersconcierge/lib/codecs/schema/vehicle";
import { VehicleBodyTypeSchema } from "@thedealersconcierge/lib/codecs/tdc";
import {
  VehicleBodyType,
  VehicleCondition,
  VehiclePrincipalPriorUse,
  VehicleType,
} from "~/__generated__/backend/zeus";
import {
  stringToVehicleBodyType,
  stringToVehicleCondition,
  stringToVehiclePrincipalPriorUse,
} from "~/lib/enumMap";

export type VehicleFormType = {
  stockNumber?: string;
  vin: string;
  bodyType: VehicleBodyType | undefined;
  make: string;
  model: string;
  year: string;
  color: string;
  condition: VehicleCondition | undefined;
  isUsed: "USED" | "NEW" | undefined;
  mileage: string;
  trim: string;
  principalPriorUse: VehiclePrincipalPriorUse | undefined;
};

type FormKeys = keyof VehicleFormType;

type Props = {
  form: ReactFormApi<VehicleFormType>;
  vehicleType: VehicleType;
  isSubmitting: boolean;
  handleCancel: () => void;
};

export default function VehicleForm({
  form,
  vehicleType,
  isSubmitting,
  handleCancel,
}: Props) {
  return (
    <>
      <div className="grid grid-cols-2 gap-6 w-full">
        {vehicleType === VehicleType.TRADE && (
          <form.Field name="condition">
            {(field) => {
              return (
                <Select
                  options={VehicleConditionSchema.options.map((o) => {
                    return {
                      value: o.value,
                      label: o.description ?? o.value,
                    };
                  })}
                  value={field.state.value ?? ""}
                  label="Condition"
                  assistiveMessage="Select the correct option"
                  placeholder="E.g., Excellent"
                  required={false}
                  disabled={isSubmitting}
                  onSelect={(option) => {
                    field.handleChange(stringToVehicleCondition(option.value));
                  }}
                />
              );
            }}
          </form.Field>
        )}

        {vehicleType === VehicleType.PURCHASE && (
          <form.Field name="principalPriorUse">
            {(field) => {
              return (
                <form.Subscribe>
                  {(f) => {
                    return (
                      <Select
                        options={VehiclePrincipalPriorUseSchema.options.map(
                          (o) => {
                            return {
                              value: o.value,
                              label: o.description ?? o.value,
                            };
                          }
                        )}
                        value={field.state.value ?? ""}
                        label="Principal Prior Use"
                        assistiveMessage="Select the correct option"
                        placeholder="E.g., Rental Vehicle"
                        required={f.values.isUsed?.toUpperCase() === "USED"}
                        disabled={isSubmitting}
                        onSelect={(option) => {
                          field.handleChange(
                            stringToVehiclePrincipalPriorUse(option.value)
                          );
                        }}
                      />
                    );
                  }}
                </form.Subscribe>
              );
            }}
          </form.Field>
        )}

        <form.Field name="isUsed">
          {(field) => {
            return (
              <Select
                options={[
                  { value: "USED" as const, label: "Used" },
                  { value: "NEW" as const, label: "New" },
                ]}
                value={field.state.value}
                label="Type"
                assistiveMessage="Select the correct option"
                placeholder="E.g., New"
                required={false}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(option.value === "NEW" ? "NEW" : "USED");
                }}
              />
            );
          }}
        </form.Field>

        <form.Field name="vin">
          {(field) => {
            return (
              <TextInput
                maxLength={17}
                value={field.state.value}
                label="VIN Number"
                assistiveMessage="Type in the value"
                placeholder="VIN Number"
                required={vehicleType === VehicleType.PURCHASE}
                disabled={isSubmitting}
                onChange={field.handleChange}
              />
            );
          }}
        </form.Field>

        <form.Field name="bodyType">
          {(field) => {
            return (
              <Select
                options={VehicleBodyTypeSchema.options.map((o) => {
                  return {
                    value: o.value,
                    label: o.description ?? o.value,
                  };
                })}
                value={field.state.value ?? ""}
                label="Body Type"
                assistiveMessage="Select the correct option"
                placeholder="E.g., Coupe"
                required={false}
                disabled={isSubmitting}
                onSelect={(option) => {
                  field.handleChange(stringToVehicleBodyType(option.value));
                }}
              />
            );
          }}
        </form.Field>

        <form.Field name="make">
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label="Make"
                assistiveMessage="Type in the value"
                placeholder="Make"
                required={vehicleType === VehicleType.PURCHASE}
                disabled={isSubmitting}
                onChange={field.handleChange}
              />
            );
          }}
        </form.Field>

        <form.Field name="model">
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label="Model"
                assistiveMessage="Type in the value"
                placeholder="Model"
                required={vehicleType === VehicleType.PURCHASE}
                disabled={isSubmitting}
                onChange={field.handleChange}
              />
            );
          }}
        </form.Field>

        <form.Field name="year">
          {(field) => {
            return (
              <TextInput
                value={field.state.value}
                label="Year"
                assistiveMessage="Type in the value"
                placeholder="E.g., 2023"
                required={vehicleType === VehicleType.PURCHASE}
                disabled={isSubmitting}
                onChange={field.handleChange}
              />
            );
          }}
        </form.Field>

        {vehicleType === VehicleType.TRADE && (
          <form.Field name="color">
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label="Color"
                  assistiveMessage="Type in the value"
                  placeholder="E.g., White"
                  required={false}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                />
              );
            }}
          </form.Field>
        )}

        <form.Field name="mileage">
          {(field) => {
            return (
              <NumberInput
                value={field.state.value}
                label="Mileage"
                assistiveMessage="Type in the value"
                placeholder="E.g., 32,004"
                required={false}
                disabled={isSubmitting}
                onChange={field.handleChange}
              />
            );
          }}
        </form.Field>

        {vehicleType === VehicleType.PURCHASE && (
          <form.Field name="trim">
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label="Trim"
                  assistiveMessage="Type in the value"
                  placeholder="E.g., Touring"
                  required={false}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                />
              );
            }}
          </form.Field>
        )}
      </div>

      <div className="flex flex-row justify-between">
        <Button
          label="Cancel"
          variant="SECONDARY"
          type="button"
          disabled={isSubmitting}
          onClick={handleCancel}
        />

        <form.Subscribe>
          {(f) => {
            let canSubmit = true;

            if (vehicleType === VehicleType.PURCHASE) {
              const requiredFields: FormKeys[] = [
                "make",
                "model",
                "year",
                "vin",
              ];

              if (f.values.isUsed?.toUpperCase() === "USED") {
                requiredFields.push("principalPriorUse");
              }

              canSubmit = requiredFields.every((field) =>
                Boolean(f.values[field])
              );
            }

            return (
              <Button
                label="Save"
                type="submit"
                disabled={!canSubmit}
                isLoading={isSubmitting}
              />
            );
          }}
        </form.Subscribe>
      </div>
    </>
  );
}
