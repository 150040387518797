import { Tag } from "@thedealersconcierge/components";
import { TransactionSource } from "@thedealersconcierge/lib/codecs/tdc";
import { getHumanReadableTransactionSource } from "@thedealersconcierge/lib/codecs/util";
import { FC } from "react";

const SourceTag: FC<{
  source: TransactionSource;
}> = ({ source }) => {
  return (
    <Tag
      color={source === "KIOSK" ? "LIGHT_PINK" : "PINK"}
      label={getHumanReadableTransactionSource(source)}
    />
  );
};

export default SourceTag;
