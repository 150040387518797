import { Updater } from "@tanstack/react-form-old";
import { useQuery } from "@tanstack/react-query";
import { UserRole } from "@thedealersconcierge/lib/codecs/tdc";
import { FC, useState } from "react";
import employeeSelector from "~/selectors/employeeSelector";
import { Pagination } from "../../_dashboardUtils";

import Typeahead, {
  TypeaheadOption,
} from "@thedealersconcierge/components/src/input/typeahead";
import { getReadableRole } from "~/lib/enumReadable";

const UserDropDownField: FC<{
  fieldName: string;
  value: string;
  handleChange: (updater: Updater<string>) => void;
  labeltext: string;
  roleToDisplay: UserRole;
  dealershipId?: string;
}> = ({
  fieldName,
  value,
  handleChange,
  labeltext,
  roleToDisplay,
  dealershipId,
}) => {
  // This tracks initial displayed user, and increment per display more
  const NUMBER_OF_USER_DISPLAYED = 5;
  const [page, setPage] = useState<Pagination>({
    currentPage: 1,
    direction: "after",
    pageSize: 10,
  });

  const [searchName, setSearchName] = useState<string>();
  const { data, isLoading } = useQuery(
    employeeSelector(roleToDisplay, dealershipId, page, searchName)
  );

  const userOptions: TypeaheadOption<string>[] =
    data?.dealership?.users?.edges?.map((e) => ({
      key: e.node?.user?.id ?? "no-key",
      label: `${e.node?.user?.firstName} ${e.node?.user?.lastName}`,
      subtext: `${getReadableRole(e.node?.role)}`,
      value: e.node?.user?.id ?? "no-value",
    })) ?? [];

  const handleSelect = (selected: TypeaheadOption<string>) => {
    handleChange(selected.value);
  };

  return (
    <div className="flex flex-row justify-between gap-4 items-start">
      <div className="flex-grow">
        <Typeahead<string>
          key={`user_dropdown_for_role_${roleToDisplay}`}
          options={userOptions}
          onSelect={handleSelect}
          onSearch={(searchString: string) => {
            setSearchName(searchString);
          }}
          displayItemCount={NUMBER_OF_USER_DISPLAYED}
          displayMoreType="WHEN_HAVE_REMAINING"
          displayMoreHandler={() => {
            setPage((prev) => {
              return {
                ...prev,
                pageSize: prev.pageSize + NUMBER_OF_USER_DISPLAYED,
              };
            });
          }}
          title={labeltext}
          isLoading={isLoading}
          selectedItemKey={value} // To preselect
        />
      </div>
    </div>
  );
};

export default UserDropDownField;
