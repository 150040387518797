import { z } from "zod";

// We save the schema here as long as the schema is only used in the presentation
// layer. This might be subject to change. The key question is how much should be
// modelled through Prisma models and how much should be modelled through zod schemas

// Define sub-schemas first
const jsiRecordSchema = z.object({
  date: z.string(),
  record_type: z.string(),
  brander_city: z.string(),
  brander_code: z.string(),
  brander_name: z.string(),
  brander_email: z.string().optional(),
  brander_phone: z.string(),
  brander_state: z.string(),
  intended_for_export: z.string().optional(),
  vehicle_disposition: z.string(),
});

const saleRecordSchema = z.object({
  vin: z.string(),
  date: z.string(),
  type: z.string(),
  fuel_type: z.string().optional(),
  seller_city: z.string(),
  seller_name: z.string(),
  seller_type: z.string().optional(),
  seller_state: z.string(),
  vehicle_make: z.string(),
  vehicle_trim: z.string().optional(),
  vehicle_year: z.string(),
  listing_price: z.string(),
  vehicle_model: z.string().optional(),
  vehicle_color: z.string().optional(),
  vehicle_title: z.string().optional(),
  seller_country: z.string(),
  vehicle_mileage: z.string(),
});

const checkRecordSchema = z.object({
  date: z.string(),
  brand_code: z.string(),
  brand_title: z.string(),
  brander_code: z.string(),
  brander_name: z.string(),
  brander_type: z.string(),
});

const titleRecordSchema = z.object({
  vin: z.string(),
  date: z.string(),
  meter: z.string(),
  state: z.string(),
  current: z.boolean(),
  meter_unit: z.string(),
});

const attributesSchema = z.object({
  vin: z.string(),
  make: z.string(),
  trim: z.string(),
  year: z.string(),
  model: z.string(),
  style: z.string(),
  engine: z.string(),
  made_in: z.string(),
  trim_id: z.string(),
  fuel_type: z.string(),
  city_mileage: z.string(),
  fuel_capacity: z.string(),
  invoice_price: z.string(),
  overall_width: z.string(),
  steering_type: z.string(),
  overall_height: z.string(),
  overall_length: z.string(),
  highway_mileage: z.string(),
  optional_seating: z.string(),
  standard_seating: z.string(),
  "anti-brake_system": z.string(),
  gross_vehicle_weight_rating: z.string(),
  manufacturer_suggested_retail_price: z.string(),
});

// Main schema
export const vehicleHistorySchema = z.object({
  id: z.string(),
  jsi: z.array(jsiRecordSchema),
  lie: z.array(z.unknown()),
  vin: z.string(),
  date: z.string(),
  mode: z.string(),
  clean: z.boolean(),
  error: z.string(),
  sales: z.array(saleRecordSchema),
  checks: z.array(checkRecordSchema),
  thefts: z.array(z.unknown()),
  titles: z.array(titleRecordSchema),
  salvage: z.array(z.unknown()),
  success: z.boolean(),
  accidents: z.array(z.unknown()),
  attributes: attributesSchema,
});
