import { useQuery } from "@tanstack/react-query";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import {
  DashboardUserRole,
  DashboardUserRoleSchema,
  UserRole,
} from "@thedealersconcierge/lib/codecs/tdc";
import { formatPhoneNumber } from "react-phone-number-input";

import { captureException } from "@sentry/react";
import { DropdownCheckboxOption } from "@thedealersconcierge/components/src/input/dropdownCheckbox";
import { useAtomValue } from "jotai";
import { toast } from "react-toastify";
import { Fragment } from "react/jsx-runtime";
import { Role } from "~/__generated__/backend/zeus";
import suspendStaffAction from "~/actions/userDealerships/suspendStaffAction";
import updateUserDealershipRoleAction from "~/actions/userDealerships/updateUserDealershipRoleAction";
import createUserGroupAction from "~/actions/userGroups/createUserGroupAction";
import suspendGroupUserAction from "~/actions/userGroups/suspendUserGroupAction";
import updateUserGroupRoleAction from "~/actions/userGroups/updateUserGroupRoleAction";
import { BreadCrumb, BreadCrumbsContainer } from "~/components/BreadCrumbs";
import Button from "~/components/design-system-components/Button";
import { useConfirmationModal } from "~/components/design-system-components/ConfirmationModal";
import Dropdown from "~/components/design-system-components/Dropdown";
import PulsingStatusIndicator from "~/components/design-system-components/PulsingStatusIndicator";
import SectionDataRow from "~/components/SectionDataRow";
import { gqlQueryClient } from "~/lib/backend";
import { getReadableRole } from "~/lib/enumReadable";
import { getInitials } from "~/lib/helpers";
import meQuery from "~/query/meQuery";
import { Link, useParams } from "~/router";
import { dealershipAtom } from "~/state";
import { DashboardRoleBadge } from "../_components/UserRoleBadge";
import CDKConfiguration from "./_components/CDKConfiguration";

const StaffPage = () => {
  const { showModal, ConfirmationModalComponent } = useConfirmationModal();
  const dealership = useAtomValue(dealershipAtom);
  const currentDealership = useAtomValue(dealershipAtom);
  const dealershipId = currentDealership?.activeDealershipPerms.dealershipId;
  const { userId } = useParams("/dashboard/users/:userId");
  const { data: meData } = useQuery(meQuery());
  const meGroupUserData = meData?.me?.user?.userGroup?.edges?.at(0)?.node;
  const isGroupAdmin = !!meGroupUserData && meGroupUserData.role === "ADMIN";

  const { data: userData, refetch: refetchUserData } = useQuery({
    queryKey: ["user", userId],
    queryFn: async () =>
      gqlQueryClient()({
        userDealership: [
          {
            userId,
            dealershipId: dealershipId ?? "no-dealership-id",
          },
          {
            user: {
              id: true,
              firstName: true,
              lastName: true,
              email: true,
              phoneNumber: true,

              userGroup: [
                {
                  first: 1,
                },
                {
                  edges: {
                    node: {
                      id: true,
                      role: true,
                      isSuspended: true,
                    },
                  },
                },
              ],
            },
            role: true,
            dealershipId: true,
            isSuspended: true,
          },
        ],
      }),
    enabled: !!dealershipId,
  });

  const user = userData?.userDealership?.user;
  const dealershipRole = userData?.userDealership?.role;
  const isDealershipSuspended = userData?.userDealership?.isSuspended;

  const userGroup =
    userData?.userDealership?.user?.userGroup?.edges?.at(0)?.node;
  const groupRole =
    userData?.userDealership?.user?.userGroup?.edges?.at(0)?.node?.role;
  const isGroupSuspended =
    userData?.userDealership?.user?.userGroup?.edges?.at(0)?.node?.isSuspended;

  const canManageUser = permissionChecker(
    "manageUsers",
    dealership?.activeDealershipPerms
  );

  const handleUpdateUserDealershipStatus = async (
    userId: string,
    isSuspended: boolean
  ) => {
    try {
      const wording = isSuspended ? "activate" : "deactivate";

      const content = (
        <div className="text-body">
          <br />
          You're about to <b>{wording}</b> the user account with the email{" "}
          <span className="text-primary-blue">{user?.email}</span>.
          <br />
          <br />
          The user still stays associated to existing transactions after being
          deactivated.
          <br />
          <br />
        </div>
      );
      const confirmed = await showModal({
        content: content,
        title: "Update user status",
        size: "LARGE",
      });

      if (confirmed) {
        await suspendStaffAction(userId, !isSuspended);
        toast.success("Succeed updated user status");
        await refetchUserData();
      }
    } catch (e) {
      captureException(`Failed to update user status: ${e}`);
      toast.error("Failed to update user status, contact admin");
    }
  };

  const handleUpdateUserGroupStatus = async (
    userId: string,
    isSuspended: boolean
  ) => {
    try {
      const wording = isSuspended ? "activate" : "deactivate";

      const content = (
        <div className="text-body">
          <br />
          You're about to <b>{wording} user group account</b> with the email{" "}
          <span className="text-primary-blue">{user?.email}</span>.
          <br />
          <br />
          The user still stays associated to existing transactions after being
          deactivated.
          <br />
          <br />
        </div>
      );
      const confirmed = await showModal({
        content: content,
        title: "Update user group status",
        size: "LARGE",
      });

      if (confirmed) {
        await suspendGroupUserAction(userId, !isSuspended);
        toast.success("Succeed updated user group status");

        await refetchUserData();
      }
    } catch (e) {
      captureException(`Failed to update user group status: ${e}`);
      toast.error("Failed to update user group status, contact admin");
    }
  };

  const handleUpdateUserDealershipRole = async (
    userId: string,
    newRole: string
  ) => {
    try {
      const role = UserRole.parse(newRole) as Role;
      if (!role) {
        toast.error("Invalid Role");
        return;
      }

      if (!dealershipId) {
        toast.error("Invalid Dealership id");
        return;
      }

      const confirmed = await showModal({
        content: (
          <div>
            Are you sure you want to change staff role into{" "}
            <b>{getReadableRole(newRole)}</b>?
          </div>
        ),
        title: "Update staff role",
      });

      if (confirmed) {
        await updateUserDealershipRoleAction(userId, role);
        await refetchUserData();
        toast.success("Succeed updated staff role");
      }
    } catch (e) {
      captureException(`Failed to update user role: ${e}`);
      toast.error("Failed to update staff role, contact admin");
    }
  };

  const handleUpsertUserGroupRole = async (userId: string, newRole: string) => {
    try {
      const role = UserRole.parse(newRole) as Role;
      if (!role) {
        toast.error("Invalid Role");
        return;
      }

      if (!dealershipId) {
        toast.error("Invalid Dealership id");
        return;
      }

      const confirmed = await showModal({
        content: (
          <div>
            Are you sure you want to change the user group role into{" "}
            <b>{getReadableRole(newRole)}</b>?
          </div>
        ),
        title: "Update user group role",
      });

      if (confirmed) {
        // Already have role then we update
        // Else create new user
        if (groupRole) {
          await updateUserGroupRoleAction(userId, role);
        } else {
          await createUserGroupAction(userId, dealershipId, role);
        }

        await refetchUserData();
        toast.success("Succeed updated user group role");
      }
    } catch (e) {
      captureException(`Failed to update user role: ${e}`);
      toast.error("Failed to update user group role, contact admin");
    }
  };

  const roleOptions: DropdownCheckboxOption<DashboardUserRole>[] =
    DashboardUserRoleSchema.options.map(
      (role) =>
        ({
          label: getReadableRole(role.value),
          value: DashboardUserRoleSchema.parse(role.value),
          key: role.value,
        }) as DropdownCheckboxOption<DashboardUserRole>
    );

  return (
    <div className="flex flex-col space-y-8">
      <BreadCrumbsContainer>
        <BreadCrumb title="Dealership">
          <Link to={"/dashboard"}>Dealership</Link>
        </BreadCrumb>

        <BreadCrumb title="Users">
          <Link to={"/dashboard/users"}>Users</Link>
        </BreadCrumb>

        {user && (
          <BreadCrumb
            title={`${user.firstName} ${user.lastName}${
              isDealershipSuspended ? " (Deactivated)" : ""
            }`}
            className="text-very-dark-gray" // We have to explicitly set the darker text color hear as conditionally changing the title doesn't properly update the color
          />
        )}
      </BreadCrumbsContainer>

      <div className="flex flex-col w-full items-center space-y-8 pb-16 overflow-y-scroll">
        <div className="flex flex-col space-y-8 bg-white rounded-lg shadow-lg p-8 w-full max-w-3xl">
          <div className="flex flex-row space-x-4">
            <div>
              {/* Sorry Matti, I have removed the profile picture from the user, as it was a source of information leakage between
              dealerships. My impressions is that this was not used, when it is needed, let's sync up on where to save the profile picture
              (Maybe StaffInformation?) */}
              {/* {user?.profilePicture?.url && (
                <img
                  className="rounded-lg h-full min-w-10 object-cover"
                  src={user.profilePicture.url}
                />
              )} */}

              <div className="flex items-center justify-center min-w-40 h-full rounded-lg text-xl font-roboto font-medium bg-very-light-gray text-dark-gray">
                {getInitials(user?.firstName, user?.lastName)}
              </div>
            </div>

            <div className="flex flex-col space-y-8 w-full">
              <div className="flex flex-row justify-between">
                <div className="text-heading-2">
                  {user?.firstName} {user?.lastName}
                  {isDealershipSuspended ? (
                    <span className="text-dark-gray"> (Deactivated)</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="flex flex-col space-y-4 w-full">
                <SectionDataRow label="Email" value={user?.email} />

                <SectionDataRow
                  label="Phone number"
                  value={formatPhoneNumber(user?.phoneNumber ?? "")}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8">
            <div className="flex flex-col gap-4">
              <p className="font-semibold ">Dealership Level Permissions</p>

              <div className="grid grid-cols-6 gap-4 items-center">
                <p className="text-body text-dark-gray">Role</p>
                <div className="col-span-2">
                  {canManageUser ? (
                    <Dropdown
                      onSelect={(newRole) => {
                        if (!userData?.userDealership?.user?.id) return;

                        handleUpdateUserDealershipRole(
                          userData?.userDealership?.user?.id,
                          newRole
                        );
                      }}
                      options={roleOptions}
                      placeholder="Select a Role"
                      size="XS"
                      value={dealershipRole}
                    />
                  ) : (
                    <DashboardRoleBadge role={dealershipRole} />
                  )}
                </div>
              </div>

              <div className="grid grid-cols-6 gap-4 items-center">
                <p className="text-body text-dark-gray">Status</p>
                <PulsingStatusIndicator
                  active={isDealershipSuspended ?? false}
                  text={isDealershipSuspended ? "Inactive" : "Active"}
                />
                {canManageUser && (
                  <Button
                    variant={isDealershipSuspended ? "GHOST" : "GHOST_DANGER"}
                    size="XS"
                    onClick={() => {
                      if (typeof isDealershipSuspended !== "boolean") return;
                      handleUpdateUserDealershipStatus(
                        userId,
                        isDealershipSuspended
                      );
                    }}
                  >
                    {isDealershipSuspended ? "Activate" : "Deactivate"}
                  </Button>
                )}
              </div>
            </div>

            {isGroupAdmin && (
              <div className="flex flex-col gap-4">
                <p className="font-semibold ">Group Level Permissions</p>
                <div className="grid grid-cols-6 gap-4 items-center">
                  <p className="text-body text-dark-gray">Role</p>
                  <div className="col-span-2">
                    {canManageUser ? (
                      <Dropdown
                        onSelect={(newRole) => {
                          if (!userData?.userDealership?.user?.id) return;
                          handleUpsertUserGroupRole(
                            userData?.userDealership?.user?.id,
                            newRole
                          );
                        }}
                        options={roleOptions}
                        placeholder="Select a Role"
                        size="XS"
                        value={groupRole}
                      />
                    ) : (
                      <DashboardRoleBadge role={groupRole} />
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-6 gap-4">
                  <p className="text-body text-dark-gray items-center">
                    Status
                  </p>

                  {groupRole ? (
                    <Fragment>
                      <PulsingStatusIndicator
                        active={isGroupSuspended ?? false}
                        text={isGroupSuspended ? "Inactive" : "Active"}
                      />
                      {canManageUser && (
                        <Button
                          variant={isGroupSuspended ? "GHOST" : "GHOST_DANGER"}
                          size="XS"
                          onClick={() => {
                            if (typeof isGroupSuspended !== "boolean") return;
                            handleUpdateUserGroupStatus(
                              userId,
                              isGroupSuspended
                            );
                          }}
                        >
                          {isGroupSuspended ? "Activate" : "Deactivate"}
                        </Button>
                      )}
                    </Fragment>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            )}
          </div>

          {dealership?.activeDealershipPerms.role === "ADMIN" && ( // Only admins can configure the CDK attributes
            <CDKConfiguration
              userId={userId}
              dealershipId={dealership?.activeDealershipPerms.dealershipId}
              isSuspended={!!userData?.userDealership?.isSuspended}
            />
          )}
        </div>
      </div>
      {ConfirmationModalComponent}
    </div>
  );
};
export default StaffPage;
