import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { OrderBy } from "~/__generated__/backend/zeus";
import Button from "~/components/Button";
import { gqlQueryClient } from "~/lib/backend";
import { vehicleHistorySchema } from "./vin-audit-schema";

type Props = {
  transactionId: string;
};

/**
 * Button and modal for Vin Audit
 *
 * Currently this only works for trade vehicles
 *
 * NOTE: This feature is currently not available and the button will generally not be shown
 */
export default function VinAuditModal({ transactionId }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useQuery({
    // We can safely reuse the same queryKey for both the trade and purchase vehicle
    queryKey: ["VinAudit", transactionId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          {
            id: transactionId,
          },
          {
            tradeVehicle: {
              updatedAt: true,
              vinAuditReports: [
                {
                  first: 1,
                  orderby: {
                    createdAt: OrderBy.Desc,
                  },
                },
                {
                  edges: {
                    node: {
                      id: true,
                      createdAt: true,
                      updatedAt: true,
                      response: true,
                    },
                  },
                },
              ],
            },
          },
        ],
      }),
  });

  const vinAuditNode =
    data?.transaction?.tradeVehicle?.vinAuditReports?.edges?.at(0)?.node;

  const auditReportParse = vehicleHistorySchema.safeParse(
    vinAuditNode?.response
  );

  const auditreport = auditReportParse.data;

  return (
    <>
      {/* Only show the button if is a report */}
      {vinAuditNode !== undefined && (
        <Button
          variant="SECONDARY"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Vin Audit
        </Button>
      )}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-modal"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25 backdrop-blur" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h2" className="text-heading-2">
                    Vin Audit Report
                  </Dialog.Title>

                  <div className="flex flex-col py-4">
                    <div className="py-2 flex flex-col">
                      <h4 className="heading-04"> Information</h4>
                      <div className="grid grid-cols-2">
                        {Object.entries(auditreport?.attributes ?? {}).map(
                          ([key, value]) => (
                            <Fragment key={key}>
                              <div>{key}</div>
                              <div>{value}</div>
                            </Fragment>
                          )
                        )}
                      </div>
                    </div>

                    <hr />

                    <div className="py-2 flex flex-col">
                      <h4 className="heading-04"> Junk and Salvage</h4>
                      <div className="grid grid-cols-2">
                        {auditreport?.jsi.map((jsi) => (
                          <Fragment key={jsi.date}>
                            <div>{jsi.date}</div>
                            <div>{jsi.record_type}</div>
                          </Fragment>
                        ))}
                      </div>
                    </div>

                    <hr />

                    <div className="py-2 flex flex-col">
                      <h4 className="heading-04">Sales</h4>
                      <div className="grid grid-cols-2">
                        {auditreport?.sales.map((sale) => (
                          <Fragment key={sale.date}>
                            <div>{sale.date}</div>
                            <div>{sale.type}</div>
                          </Fragment>
                        ))}
                      </div>
                    </div>

                    <hr />

                    <div className="py-2 flex flex-col">
                      <h4 className="heading-04">Titles</h4>
                      <div className="grid grid-cols-2">
                        {auditreport?.titles.map((title) => (
                          <Fragment key={title.date}>
                            <div>{title.date}</div>
                            <div>{title.meter}</div>
                          </Fragment>
                        ))}
                      </div>
                    </div>

                    <hr />

                    {/* Don't know the structure */}
                    {/* <div className="py-2 flex flex-col">
                      <h4 className="heading-04">Thefts</h4>
                      <div className="grid grid-cols-2">
                        {auditreport?.thefts.map((theft) => (
                          <Fragment key={theft}>
                            <div>{theft.date}</div>
                            <div>{theft.meter}</div>
                          </Fragment>
                        ))}
                      </div>
                    </div> */}
                  </div>

                  <div className="flex flex-col space-y-2 p-4"></div>
                  <div className="mt-4 flex flex-row justify-end w-full space-x-4">
                    <button
                      type="button"
                      className="button-primary"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
